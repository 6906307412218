import React from 'react';
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Slide} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {Feed, CloudDownload, CloudUpload, AutoFixHigh} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import SelectComponent from "src/Component/SelectComponent";
import ServerStatCPUComponent from "./ServerStatCPUComponent";
import ServerStatRAMComponent from "./ServerStatRAMComponent";
import ServerStatDiskWriteReadComponent from "./ServerStatDiskWriteReadComponent";
import LoaderPageComponent from "src/Component/LoaderPageComponent";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {Link, useNavigate} from "react-router-dom";
import ServerDirectorySystemComponent from "./ServerDirectorySystemComponent";
import ButtonIconComponent from "src/Component/ButtonIconComponent";

const NeonLine = (props = {}) => {
    const neonLineStyle = {
        width: '100%',
        height: '1px', // Épaisseur de la ligne
        background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.2), ' + color.primary + ' 25%, ' + color.primary + ' 50%, ' + color.primary + ' 75%, rgba(0, 0, 0, 0.2))',
        backgroundSize: '150% 100%',
        animation: 'neon-move 5s ease-in-out infinite', // Animation continue
    };
    return (
        <Box sx={props.style}>
            <div style={neonLineStyle}></div>
        </Box>
    );
};

function ServerTabGeneralComponent(props) {
    // React
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [loadingInformation, setLoadingInformation] = React.useState(false);
    const [optionEnterprises, setOptionEnterprises] = React.useState([]);
    const [optionKernels, setOptionKernels] = React.useState([
        {label: 'LINUX', value: 'LINUX'},
        {label: 'WINDOWS', value: 'WINDOWS'},
        {label: 'PFSENSE', value: 'PFSENSE'}
    ]);
    const [optionHosts, setOptionHosts] = React.useState([
        {label: 'OVH', value: 'OVH'},
        {label: 'SCALEWAY', value: 'SCALEWAY'}
    ]);
    const [informations, setInformations] = React.useState({});

    // Redux
    const dispatch = useDispatch();

    // Form Info
    const [form, setForm] = React.useState({
        name: {
            id: 'name', label: 'Nom', helperText: 'Entrez le nom du serveur.', type: 'text', value: '', options: {validation: ['required']}
        }, ip: {
            id: 'ip', label: 'IP', helperText: 'Entrez l\'IP du serveur.', type: 'text', value: '', options: {validation: ['required']}
        }, host: {
            id: 'host', label: 'Hébergeur', helperText: 'Entrez l\'hébergeur du serveur.', type: 'text', value: '', options: {validation: ['required']}
        }, password: {
            id: 'password', label: 'Mot de passe', helperText: 'Entrez le mot de passe du serveur.', type: 'password', value: '', options: {validation: ['required']}
        }, enterprise: {
            id: 'enterprise', label: 'Entreprise', helperText: 'Choisissez l\'entreprise du serveur.', type: 'text', value: '', options: {validation: ['required']}
        }, vcpu: {
            id: 'vcpu', label: 'vCPU', helperText: 'Entrez le nombre de vCPU du serveur.', type: 'float', value: '', options: {validation: ['required']}
        }, ram: {
            id: 'ram', label: 'RAM', helperText: 'Entrez le nombre de RAM du serveur.', type: 'float', value: '', options: {validation: ['required']}
        }, storage: {
            id: 'storage', label: 'Stockage', helperText: 'Entrez le nombre de Stockage du serveur.', type: 'float', value: '', options: {validation: ['required']}
        }, kernel: {
            id: 'kernel', label: 'Kernel', helperText: 'Choisissez le Kernel du serveur.', type: 'text', value: '', options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoadingInformation(true);

            routingApiHandlerFetch({
                route: 'api_administrator_server', method: 'POST', params: {server: props.server ? props.server.uuid : ''}, data: handler.getDataApi(), success: (response) => {
                    handler.setLoading(false);
                    setLoadingInformation(false);
                    dispatch(snackbarReduxOpen({text: 'Serveur enregistrée avec succès.', variant: 'success'}));
                    if (!props.server) {
                        navigate(routingHandlerGetRoutePathname('administrator_server', {serverParams: response.data.server}));
                    }
                    else {
                        if (props.server.kernel !== handler.getDataApi().kernel) {
                            props.setReload(true);
                        }
                        else {
                            setInformations(handler.getDataApi());
                        }
                    }
                }, error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoadingInformation(false);
                }
            });
        }
    };

    // UseEffect
    React.useEffect(() => {
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_information',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    handler.setDataApi(response.data);
                    setInformations(response.data);
                    setTimeout(() => setLoading(false), 1000);
                },
                error: () => {
                    dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    setTimeout(() => setLoading(false), 1000);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [props.server]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_administrator_server_enum_enterprises', method: 'GET', success: (response) => {
                setOptionEnterprises(response.data);
            }, error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);

    return (loading ? <LoaderPageComponent/> : <>
        <Box sx={{width: '100%'}}>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Fade in={true} {...{timeout: 500}}>
                        <Box sx={{height: '100%'}}>
                            <ShadowBoxComponent sx={{height: '100%'}}>
                                <Box sx={{width: '100%', position: 'relative', paddingTop: '2vh'}}>
                                    <Box sx={{position: 'absolute', width: '37%', top: '25%', zIndex: 1}}>
                                        <Box sx={{color: color.textPrimary, padding: '5px'}}>
                                            <CloudDownload sx={{verticalAlign: 'bottom', marginRight: '10px'}}/>
                                            <span style={{fontSize: 'clamp(12px, 2vw, 14px)'}}>Inbound : {informations.ip}</span>
                                        </Box>
                                        <NeonLine/>
                                    </Box>

                                    <Box sx={{position: 'absolute', width: '36%', top: '57%'}}>
                                        <Box sx={{color: color.textPrimary, padding: '5px'}}>
                                            <CloudUpload sx={{verticalAlign: 'bottom', marginRight: '10px'}}/>
                                            <span style={{fontSize: 'clamp(12px, 2vw, 14px)'}}>Outbound : {informations.ipOutBound}</span>
                                        </Box>
                                        <NeonLine/>
                                    </Box>

                                    <img
                                        style={{position: 'relative', marginBottom: '5px', maxHeight: '50vh', width: '40%', marginLeft: 'auto', marginRight: 'auto', display: 'block', zIndex: 2}}
                                        src={'/images/server.png'} alt={'server'}
                                    />

                                    <Box sx={{position: 'absolute', width: '50%', top: '25%', right: 0}}>
                                        <Box sx={{color: color.textPrimary, padding: '5px', textAlign: 'right'}}>
                                            <span style={{fontSize: 'clamp(12px, 2vw, 14px)'}}>{informations.vcpu} vCPU</span>
                                            <img height={28} src="/images/cpu-icon.png" alt="SSD" style={{verticalAlign: 'bottom', marginLeft: '10px'}}/>
                                        </Box>
                                        <NeonLine/>
                                    </Box>

                                    <Box sx={{position: 'absolute', width: '50%', top: '50%', right: 0}}>
                                        <Box sx={{color: color.textPrimary, padding: '5px', textAlign: 'right'}}>
                                            <span style={{fontSize: 'clamp(12px, 2vw, 14px)'}}>{informations.ram} GB RAM</span>
                                            <img height={28} src="/images/ddr3-ram-icon.png" alt="SSD" style={{verticalAlign: 'bottom', marginLeft: '10px'}}/>
                                        </Box>
                                        <NeonLine/>
                                    </Box>

                                    <Box sx={{position: 'absolute', width: '50%', top: '75%', right: 0}}>
                                        <Box sx={{color: color.textPrimary, padding: '5px', textAlign: 'right'}}>
                                            <span style={{fontSize: 'clamp(12px, 2vw, 14px)'}}>{informations.storage} GB</span>
                                            <img height={28} src="/images/ssd-icon.png" alt="SSD" style={{verticalAlign: 'bottom', marginLeft: '10px'}}/>
                                        </Box>
                                        <NeonLine/>
                                    </Box>
                                </Box>
                            </ShadowBoxComponent>
                        </Box>
                    </Fade>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Fade in={true} {...{timeout: 500}}>
                        <Box sx={{width: '100%', height: 'calc(100% + 16px)'}}>
                            <Grid container spacing={2} alignItems="stretch" sx={{height: '100%'}}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ShadowBoxComponent sx={{height: '100%'}}>
                                        <Box sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                                            <Feed sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Information
                                        </Box>

                                        <Box sx={{position: 'absolute', top: '15px', right: '15px'}}>
                                            <ButtonIconComponent
                                                background={color.colorEditBackground}
                                                backgroundHover={color.colorEditBackgroundHover}
                                                color={color.colorEdit}
                                                icon={AutoFixHigh}
                                                onClick={() => {
                                                    navigator.clipboard.writeText('sshpass -p \'' + informations.password + '\' ssh root@' + informations.ip)
                                                        .then(() => {
                                                            dispatch(snackbarReduxOpen({text: 'Texte copié avec succès !', variant: 'success'}));
                                                        })
                                                        .catch((err) => {
                                                            dispatch(snackbarReduxOpen({text: 'Échec de la copie dans le presse-papiers !', variant: 'error'}));
                                                        });
                                                }}
                                            />
                                        </Box>

                                        <br/>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'name'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'host'} onSubmit={save} options={optionHosts}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'ip'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'password'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'enterprise'} onSubmit={save} options={optionEnterprises}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                                <SelectComponent handler={handler} id={'kernel'} onSubmit={save} options={optionKernels}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <TextFieldComponent handler={handler} id={'vcpu'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <TextFieldComponent handler={handler} id={'ram'} onSubmit={save}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <TextFieldComponent handler={handler} id={'storage'} onSubmit={save}/>
                                            </Grid>
                                        </Grid>

                                        <br/>

                                        <ButtonComponent label={'Enregistrer'} onClick={save} loading={loadingInformation}/>
                                    </ShadowBoxComponent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Grid>
                {(props.server && props.server.kernel === 'LINUX') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{width: '100%'}}>
                        <Grid container spacing={2}>
                            <Slide direction="right" in={true} {...{timeout: 500}}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <ServerStatCPUComponent server={props.server}/>
                                </Grid>
                            </Slide>
                            <Slide direction="up" in={true} {...{timeout: 500}}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <ServerStatRAMComponent server={props.server}/>
                                </Grid>
                            </Slide>
                            <Slide direction="left" in={true} {...{timeout: 500}}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <ServerStatDiskWriteReadComponent server={props.server}/>
                                </Grid>
                            </Slide>
                        </Grid>
                    </Box>
                </Grid>}
                {(props.server && props.server.kernel === 'LINUX') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ServerDirectorySystemComponent {...props}/>
                </Grid>}
            </Grid>
        </Box>
    </>);
}

export default ServerTabGeneralComponent;
