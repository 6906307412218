import React from 'react';
import {Box, Typography, Menu as MenuMUI, MenuItem, ListItemIcon, IconButton, Fade} from "@mui/material";
import {color} from "src/Config/Theme";
import {Logout, Menu, AccountCircle} from '@mui/icons-material';
import {useDispatch, useSelector} from "react-redux";
import {drawerMainLayoutReduxOpen} from "src/Redux/drawerMainLayoutRedux";
import {authenticationReduxLogout} from "src/Redux/authenticationRedux";
import {ArrowBack} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {routingApiHandlerGetRoute} from "src/Handler/RoutingApiHandler";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {styled} from "@mui/material/styles";

const useStyles = {
    appBar: {
        zIndex: 2,
        width: '100%',
        paddingBottom: '15px',
        boxSizing: 'border-box',
        position: 'sticky',
        top: 0,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        background: color.backgroundPrimary,
        height: '64px',
        borderRadius: '5px',
        marginBottom: '15px',
        borderImage: 'conic-gradient(from var(--angle), ' + color.primaryOpacity + ', ' + color.primary + ' 0.1turn, ' + color.primary + ' 0.15turn, ' + color.primaryOpacity + ' 0.25turn) 30'
    },
    avatar: {
        position: 'absolute',
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '15px',
        width: '38px',
        height: '38px',
        right: '20px',
        top: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    initials: {
        fontSize: '15px',
        color: color.textPrimary,
        fontWeight: 'bold'
    },
    menu: {
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            background: color.backgroundPrimary,
            color: color.textPrimary,
            marginTop: '12px',
            marginLeft: '0',
            maxWidth: '90%',
            minWidth: '200px',
            '& ul': {
                padding: '5px 5px 5px !important',
            },
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: color.backgroundPrimary,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
    },
    item: {
        background: 'none !important',
        width: 'auto',
        padding: '5px',
        '&:hover': {
            background: color.primaryOpacityMore + ' !important',
            color: color.primary,
            '& svg': {
                color: color.primary
            }
        }
    },
    itemBox: {
        borderRadius: '50px',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px',
    },
    itemIcon: {
        fontSize: '20px',
        color: color.textPrimary
    }
};
const LinkStyled = styled(Link)(() => ({
    textDecoration: 'none',
    color: color.textPrimary
}));

function AppBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = React.useState(false);
    const screenRedux = useSelector(state => state.screenRedux);
    const authenticationRedux = useSelector((state) => state.authenticationRedux);
    const barMainLayoutRedux = useSelector((state) => state.barMainLayoutRedux);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (screenRedux.screen) setShowMenu(!(screenRedux.screen === 'MD' || screenRedux.screen === 'LG' || screenRedux.screen === 'XL'));
    }, [screenRedux.screen]);

    return (
        <Box className={'loader-bar'} sx={useStyles.appBar}>

            {
                showMenu ? <Box sx={{display: 'inline-block', width: '64px', height: '64px', textAlign: 'center'}}>
                    <Menu sx={{padding: '18px', cursor: 'pointer', color: color.textPrimary}} onClick={() => dispatch(drawerMainLayoutReduxOpen())}/>
                </Box> : <Box sx={{height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '20px'}}>
                    {barMainLayoutRedux.urlBack && <Fade in={true} {...{timeout: 500}}>
                        <IconButton
                            sx={{'&:hover': {background: color.primaryOpacityMore}}}
                            onClick={() => {
                                navigate(barMainLayoutRedux.urlBack)
                            }}
                        >
                            <ArrowBack sx={{color: color.textPrimary}}/>
                        </IconButton>
                    </Fade>}

                    <Fade in={true} {...{timeout: 500}}>
                        <Typography sx={{color: color.textPrimary, paddingLeft: '20px'}}>{barMainLayoutRedux.title}</Typography>
                    </Fade>
                </Box>
            }

            <Box sx={{...useStyles.avatar, ...(authenticationRedux.avatar ? {background: 'none'} : {})}} onClick={handleClick}>
                {
                    authenticationRedux.uuid && (authenticationRedux.avatar ?
                        <img
                            src={routingApiHandlerGetRoute({route: 'api_administrator_user_avatar', params: {user: authenticationRedux.uuid, avatar: authenticationRedux.avatar}})}
                            alt={'Utilisateur'}
                            height={38}
                            width={38}/> :
                        <Typography sx={useStyles.initials}>{authenticationRedux.firstname[0] + authenticationRedux.lastname[0]}</Typography>)
                }
            </Box>

            <MenuMUI
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={useStyles.menu}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {
                    authenticationRedux.enterprises?.map((entreprise, index) => {
                        return <LinkStyled key={index} to={routingHandlerGetRoutePathname('enterprise', {enterprise: entreprise.uuid})}>
                            <MenuItem sx={useStyles.item}>
                                <ListItemIcon>
                                    <Box sx={useStyles.itemBox}>
                                        {
                                            entreprise.logo ? <img
                                                style={{marginTop: '2px', marginLeft: 0, borderRadius: '25px'}}
                                                src={entreprise.logo ? routingApiHandlerGetRoute({route: 'api_administrator_enterprise_logo', params: {enterprise: entreprise.uuid, logo: entreprise.logo}}) : ''}
                                                alt={entreprise.name}
                                                height={28}
                                                width={28}/> : <Box sx={useStyles.avatar}>
                                                <Typography>{entreprise.name}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </ListItemIcon>
                                {entreprise.name}
                            </MenuItem>
                        </LinkStyled>
                    })
                }
                <div style={{borderBottom: '1px solid ' + color.textSecondary}}/>
                <LinkStyled to={routingHandlerGetRoutePathname('my_profile')}>
                    <MenuItem sx={useStyles.item}>
                        <ListItemIcon>
                            <Box sx={useStyles.itemBox}>
                                <AccountCircle sx={useStyles.itemIcon} fontSize="small"/>
                            </Box>
                        </ListItemIcon>
                        Mon profil
                    </MenuItem>
                </LinkStyled>
                <MenuItem sx={useStyles.item} onClick={() => dispatch(authenticationReduxLogout())}>
                    <ListItemIcon>
                        <Box sx={useStyles.itemBox}>
                            <Logout sx={useStyles.itemIcon} fontSize="small"/>
                        </Box>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </MenuMUI>
        </Box>
    );
}

export default AppBar;
