import React from 'react';
import ReactApexChart from "react-apexcharts";
import {Box, Fade} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";

function ServerStatDiskWriteReadComponent(props) {
    // React
    const [series, setSeries] = React.useState([
        {
            name: 'Lecture',
            data: []
        },
        {
            name: 'Écriture',
            data: []
        }
    ]);
    const options = {
        chart: {
            foreColor: "#fff",
            toolbar: {
                show: false
            }
        },
        title: {
            text: ' ',
            align: 'left',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        colors: ["#6F61F0FF", "#DB1EE8"],
        grid: {
            borderColor: "#40475D"
        },
        yaxis: {
            tickAmount: 10,
            opposite: true,
            labels: {
                offsetX: -10,
                formatter: function (value) {
                    return Math.floor(value) + " kib/s";
                }
            },
        },
        xaxis: {
            type: 'datetime',
            axisTicks: {
                color: "#333"
            },
            axisBorder: {
                color: "#333"
            }
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm:ss'

            }
        },
    };

    React.useEffect(() => {
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_log_disk_write_read',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    setSeries([
                        {
                            name: 'Lecture',
                            data: response.data.read
                        },
                        {
                            name: 'Écriture',
                            data: response.data.write
                        }
                    ]);
                },
                error: () => {
                }
            });

            const loopInterval = setInterval(() => {
                routingApiHandlerFetch({
                    route: 'api_administrator_server_log_disk_write_read',
                    params: {server: props.server.uuid},
                    method: 'GET',
                    success: (response) => {
                        setSeries([
                            {
                                name: 'Lecture',
                                data: response.data.read
                            },
                            {
                                name: 'Écriture',
                                data: response.data.write
                            }
                        ]);
                    },
                    error: () => {
                    }
                });
            }, 1000 * 60);

            return () => {
                clearInterval(loopInterval);
            };
        }
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <ReactApexChart options={options} series={series} type={'line'} height={'250px'}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerStatDiskWriteReadComponent;
