import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, LinearProgress} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {AddCircleOutline, Folder, LinearScale} from "@mui/icons-material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {useDispatch} from "react-redux";

function ServerDirectorySystemComponent(props) {
    // Redux
    const dispatch = useDispatch();

    const [loadingDirectorySystem, setLoadingDirectorySystem] = React.useState(false);
    const [directorySystems, setDirectorySystems] = React.useState([]);
    const [formDirectorySystem, setFormDirectorySystem] = React.useState({
        path: {
            id: 'path', label: 'Chemin', helperText: 'Entrez le chemin du répertoire système.', type: 'text', value: '', options: {validation: ['required']}
        }
    });
    const handlerDirectorySystem = formHandlerInit(formDirectorySystem, setFormDirectorySystem);
    const [openDialogDirectorySystem, setOpenDialogDirectorySystem] = React.useState(false);
    const saveDirectorySystem = () => {
        if (handlerDirectorySystem.checkValid() < 1) {
            handlerDirectorySystem.setLoading(true);
            setLoadingDirectorySystem(true);

            routingApiHandlerFetch({
                route: 'api_administrator_server_directory_system',
                method: 'POST',
                params: {server: props.server.uuid},
                data: handlerDirectorySystem.getDataApi(),
                success: (response) => {
                    getDirectorySystem(() => {
                        setLoadingDirectorySystem(false);
                        handlerDirectorySystem.setLoading(false);
                        setOpenDialogDirectorySystem(false);
                    });
                    dispatch(snackbarReduxOpen({text: 'Répertoire Système enregistrée avec succès.', variant: 'success'}));
                }, error: (response) => {
                    setLoadingDirectorySystem(false);
                    if (response.code === 400) {
                        handlerDirectorySystem.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handlerDirectorySystem.setLoading(false);
                }
            });
        }
    };
    const getDirectorySystem = (callback = null) => {
        routingApiHandlerFetch({
            route: 'api_administrator_server_directory_system',
            params: {server: props.server.uuid},
            method: 'GET',
            success: (response) => {
                if (callback) callback();
                setDirectorySystems(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    };

    React.useEffect(() => {
        if (props.server) {
            getDirectorySystem();
            const loopInterval = setInterval(getDirectorySystem, 1000 * 60);

            return () => {
                clearInterval(loopInterval);
            };
        }
    }, [props.server]);

    return (
        <>
            <Fade in={true} {...{timeout: 500}}>
                <Fade in={true} {...{timeout: 500}}>
                    <Box sx={{width: '100%'}}>
                        <Grid container spacing={2} alignItems="stretch">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ShadowBoxComponent>
                                    <Box sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                                        <Folder sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Répertoire Système
                                        <ButtonIconComponent
                                            background={color.colorEditBackground}
                                            backgroundHover={color.colorEditBackgroundHover}
                                            color={color.colorEdit}
                                            icon={AddCircleOutline}
                                            sx={{float: 'right'}}
                                            onClick={() => setOpenDialogDirectorySystem(true)}
                                        />
                                    </Box>

                                    <br/>

                                    {
                                        directorySystems.map((directorySystem, key) => {
                                            return (
                                                <Box key={key} sx={{padding: '0px 20px'}}>
                                                    <Box textAlign="end">
                                                        <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                                            {directorySystem.directorySystemPercent}%
                                                        </Typography>
                                                    </Box>

                                                    <Box display="flex" alignItems="center">
                                                        <Typography sx={{marginRight: '10px', color: color.textPrimary, fontWeight: '600', width: '100px'}}>
                                                            {directorySystem.path} :
                                                        </Typography>
                                                        <Box flex={1}>
                                                            <LinearProgress variant="determinate"
                                                                            value={directorySystem.directorySystemPercent}
                                                                            sx={{
                                                                                '& .MuiLinearProgress-bar': {
                                                                                    backgroundColor:
                                                                                        directorySystem.directorySystemPercent < 70
                                                                                            ? color.colorSuccess
                                                                                            : directorySystem.directorySystemPercent >= 70 && directorySystem.directorySystemPercent <= 85
                                                                                                ? color.colorWarning
                                                                                                : color.colorError,
                                                                                },
                                                                            }}/>
                                                        </Box>
                                                    </Box>

                                                    <Box textAlign="center">
                                                        <Typography sx={{fontSize: '12px', color: color.textPrimary}}>
                                                            <strong>Utilisé</strong> : {directorySystem.directorySystemUsed} GB <LinearScale sx={{verticalAlign: 'middle', margin: '0 5px'}}/>
                                                            <strong>Libre</strong> : {directorySystem.directorySystemFree} GB <LinearScale sx={{verticalAlign: 'middle', margin: '0 5px'}}/>
                                                            <strong>Total</strong> : {directorySystem.directorySystemTotal} GB
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })
                                    }
                                </ShadowBoxComponent>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Fade>

            <Dialog
                open={openDialogDirectorySystem}
                onClose={() => setOpenDialogDirectorySystem(false)}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="responsive-dialog-title" sx={{background: color.backgroundPrimary, color: color.textPrimary}}>
                    Ajouter un répertoire système
                </DialogTitle>
                <DialogContent sx={{background: color.backgroundPrimary, paddingTop: '5px !important', padding: '5px 24px'}}>
                    <TextFieldComponent handler={handlerDirectorySystem} id={'path'} onSubmit={saveDirectorySystem}/>
                </DialogContent>
                <DialogActions sx={{background: color.backgroundPrimary}}>
                    <ButtonComponent onClick={() => setOpenDialogDirectorySystem(false)} label={'Annuler'} color={color.textTertiary}/>
                    <ButtonComponent onClick={saveDirectorySystem} label={'Enregistrer'} loading={loadingDirectorySystem}/>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default ServerDirectorySystemComponent;
