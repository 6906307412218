import React from 'react';
import {Box, Collapse, Fade, Typography} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import ButtonComponent from "src/Component/ButtonComponent";
import AceEditor from 'react-ace';
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {useDispatch} from "react-redux";
import LoaderPageComponent from "src/Component/LoaderPageComponent";

function ServerTabFirewallComponent(props) {
    // Redux
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    // PFSENSE
    const [switchPFSense, setSwitchPFSense] = React.useState(false);
    const savePFSense = (hasPFSense) => {
        routingApiHandlerFetch({
            route: 'api_administrator_server_firewall_pfsense',
            method: 'POST',
            params: {server: props.server.uuid},
            data: {hasPFSense: hasPFSense},
            success: () => {
                if (hasPFSense) {
                    dispatch(snackbarReduxOpen({text: 'PFSense activé.', variant: 'success'}));
                }
                else {
                    dispatch(snackbarReduxOpen({text: 'PFSense désactivé.', variant: 'warning'}));
                }
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    };

    // IPTABLES
    const [loadingIPTables, setLoadingIPTables] = React.useState(false);
    const [switchIPTables, setSwitchIPTables] = React.useState(false);
    const [firewallScriptIPTables, setFirewallScriptIPTables] = React.useState('');
    const [hasReconfigure, setHasReconfigure] = React.useState(false);
    const onChange = (newValue) => {
        setFirewallScriptIPTables(newValue);
    };
    const save = (reconfigure, switchIPTablesOverride = null) => {
        setLoadingIPTables(true);
        let firewallHasIPTables = switchIPTablesOverride !== null ? switchIPTablesOverride : switchIPTables;

        routingApiHandlerFetch({
            route: 'api_administrator_server_firewall_iptables',
            method: 'POST',
            params: {server: props.server.uuid},
            data: {
                firewallScriptIPTables: firewallScriptIPTables,
                firewallHasIPTables: firewallHasIPTables,
                reconfigure: reconfigure
            },
            success: () => {
                setLoadingIPTables(false);
                if (reconfigure) {
                    dispatch(snackbarReduxOpen({text: 'IPTables activé et reconfiguration.', variant: 'success'}))
                    setHasReconfigure(true);
                }
                else {
                    if(firewallHasIPTables) {
                        dispatch(snackbarReduxOpen({text: 'IPTables activé.', variant: 'success'}));
                    }else {
                        dispatch(snackbarReduxOpen({text: 'IPTables désactivé', variant: 'warning'}));
                    }
                }
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                setLoadingIPTables(false);
            }
        });
    };

    React.useEffect(() => {
        setLoading(true);
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_firewall',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    setSwitchPFSense(response.data.firewallHasPFSense);
                    setFirewallScriptIPTables(response.data.firewallScriptIPTables);
                    setSwitchIPTables(response.data.firewallHasIPTables);
                    setTimeout(() => setLoading(false), 750);
                },
                error: () => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [props.server]);

    return (loading ? <LoaderPageComponent/> : <>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{width: '100%'}}>
                    <ShadowBoxComponent>
                        <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                            <img
                                src={'/images/pfsense.png'}
                                height={40}
                                width={110}/>
                            <Box sx={{float: 'right'}}>
                                <input defaultChecked={switchPFSense} checked={switchPFSense} type="checkbox" className={'checkboxInput'} id="switchPFSense"/>
                                <label onClick={() => {
                                    savePFSense(!switchPFSense);
                                    setSwitchPFSense(!switchPFSense);
                                }} htmlFor="checkboxInput" className="toggleSwitch"/>
                            </Box>
                        </Typography>
                    </ShadowBoxComponent>
                </Box>
            </Fade>

            <br/>

            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{width: '100%'}}>
                    <ShadowBoxComponent>
                        <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                            <img
                                src={'/images/iptables.png'}
                                height={50}
                                width={75}/>
                            <Box sx={{float: 'right'}}>
                                <input defaultChecked={switchIPTables} checked={switchIPTables} type="checkbox" className={'checkboxInput'} id="switchIPTables"/>
                                <label onClick={() => {
                                    setSwitchIPTables(!switchIPTables);
                                    if (!switchIPTables === false) {
                                        save(false, !switchIPTables);
                                    }
                                }} htmlFor="checkboxInput" className="toggleSwitch"/>
                            </Box>
                        </Typography>

                        <Collapse in={switchIPTables}>
                            <br/>
                            <AceEditor
                                style={{width: '100%', height: 'calc(100vh - 347px)'}}
                                mode="ruby"
                                theme="solarized_dark"
                                onChange={onChange}
                                name="firewall"
                                editorProps={{$blockScrolling: true}}
                                value={firewallScriptIPTables}
                                setOptions={{
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}
                            />

                            <br/>

                            <ButtonComponent label={'Enregistrer'} onClick={() => save(false)} loading={loadingIPTables} sx={{marginBottom: '10px'}}/>
                            <ButtonComponent
                                label={'Enregistrer & Reconfigurer'}
                                onClick={() => save(true)}
                                disabled={hasReconfigure}
                                loading={loadingIPTables}
                                color={color.secondary}
                                colorHover={color.secondaryHover}
                            />
                        </Collapse>
                    </ShadowBoxComponent>
                </Box>
            </Fade>
        </>
    );
}

export default ServerTabFirewallComponent;
